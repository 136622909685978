<div class="dynamic-table mat-elevation-z8">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="announceSortChange($event)"
    class="mat-elevation-z8 table-unit"
  >
    <!-- <ng-container matColumnDef="clickableColumn">
      <th mat-header-cell *matHeaderCellDef class="table-header">{{mainColumnTitle}}</th>
      <td (click)="openCareerDetail(element.idCar, element.orgUnitId .idOgUnit)"  class="detail-unit" mat-cell *matCellDef="let element">
         <button (click)="setSelectedRow(element) " mat-icon-button>
           <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
          </svg>
        </button>
         <span class="vertical-line"></span>

        <span class="id-text">{{ element.idCar }}</span>

      </td>
    </ng-container> -->
    <ng-container
      class="data-container"
      *ngFor="let column of displayedColumns"
      [matColumnDef]="column"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ column === "name" ? "Nombre" : "Nro." }}
      </th>
      <!-- <th mat-header-cell *matHeaderCellDef class="table-header">{{ column.replace("_", " ") }}</th> -->
      <td
        (click)="openCareerDetail(element.idCar)"
        class="detail-unit"
        mat-cell
        *matCellDef="let element"
      >
        {{ element[column] }}
      </td>
    </ng-container>

    <ng-container matColumnDef="editButtons">
      <th mat-header-cell *matHeaderCellDef>
        <button (click)="enabledOrDisabled()" class="btn-disabled">
          <mat-icon
            aria-hidden="false"
            fontIcon="keyboard_double_arrow_left"
            *ngIf="disabledCareers"
          ></mat-icon>
          <mat-icon
            aria-hidden="false"
            fontIcon="keyboard_double_arrow_right"
            *ngIf="!disabledCareers"
          ></mat-icon>
          <label>{{ buttonText }}</label>
        </button>
      </th>
      <td mat-cell *matCellDef="let element" class="edit-container">
        <span class="vertical-line"></span>
        <button
          *ngIf="!disabledCareers"
          (click)="openModifyCareer(element.idCar)"
          mat-icon-button
        >
          <mat-icon fontSet="material-icons-outlined"> edit</mat-icon>
        </button>

        <button
          *ngIf="!disabledCareers"
          (click)="deleteCareer(element.idCar)"
          class="delete-component"
          mat-icon-button
        >
          <mat-icon fontSet="material-icons-outlined"> cancel</mat-icon>
        </button>
        <button
          *ngIf="disabledCareers"
          (click)="enabledCareer(element.idCar)"
          mat-icon-button
          class="edit-buttons"
        >
          <mat-icon
            aria-hidden="false"
            fontIcon="add_circle_outline"
          ></mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToShow"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToShow; let i = index"
      [class.disabled-status]="disabledCareers"
      [class.selected-row]="i === selectedRowIndex"
    ></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  ></mat-paginator>
</div>
