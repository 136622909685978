import { LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';
import { NotSavedModalComponent } from 'src/app/shared/components/not-saved-modal/not-saved-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MyApiService } from 'src/services/connection';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OrganizationalUnits } from '../organizational-unit.service';

@Component({
  selector: 'organizationalUnit-table',
  styleUrls: ['organizationalUnit-table.component.sass'],
  templateUrl: 'organizationalUnit-table.component.html',
  standalone: true,
  imports: [
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule
  ]
})
export class OrganizationalUnitTable implements AfterViewInit {
  constructor(
    private myApiService: MyApiService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private organizationalUnitsService: OrganizationalUnits,
  ) {}

  @ViewChild(MatSort)
  sort!: MatSort;

  iconColumn: string[] = ['Acciones'];

  isChecked: boolean = false;

  mainColumnTitle: string = 'idOgUnit';
  displayedColumns: string[] = ['idOgUnit', 'name', 'Acciones'];
  columnsToShow: string[] = ['idOgUnit', 'name', 'editButtons'];
  dataSource = new MatTableDataSource<any>();
  selectedRowIndex: number = -1;
  dataRes: any
  allData:any
  disabledUnits: boolean = false
  buttonText: string = 'Ver Deshabilitadas';

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  checkboxStateMap: { [key: number]: boolean } = {};


  async openOrganizationalUnitDetail(idOrgUnit: number) {
    this.organizationalUnitsService.openOrganizationalUnitDetail(idOrgUnit);
  }

  async openModifyOrganizationalUnit(idOrgUnit: number) {
    this.organizationalUnitsService.setEditForm(false);
    this.organizationalUnitsService.openModifyOrganizationalUnit(idOrgUnit);
  }

  setSelectedRow(element: any): void {
    this.selectedRowIndex = this.dataSource.data.indexOf(element);
  }

  deleteOrganizationalUnit(idOgUnit: number) {
    const dialogRef = this.dialog.open(NotSavedModalComponent, {
      data: {
        title: 'Eliminando Unidad Organizacional',
        subtitle: 'Estás por eliminar una Unidad Organizacional.',
        subtitleQuestion: '¿Deseas continuar?',
        acceptButton: 'Confirmar',
        cancelButton: 'Cancelar',
        icon: false,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result === "accept") {
         try {
          this.dataRes = await this.myApiService.patch(`organizational-unit/disable/${idOgUnit}`);
          await this.organizationalUnitsService.loadOrganizationalUnits()

    } catch (error) {
      console.error('Error fetching data:', error);
    }
      }
      console.log(`Dialog result: ${result}`);
    });
  }

  async enabledOgUnit(idOgUnit: number) {
    const dialogRef = this.dialog.open(NotSavedModalComponent, {
      data: {
        title: 'Habilitando Unidad Organizacional',
        subtitle: 'Estás por habilitar una Unidad Organizacional.',
        subtitleQuestion: '¿Deseas continuar?',
        acceptButton: 'Confirmar',
        cancelButton: 'Cancelar',
        icon: false,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result === "accept") {
        try {
          this.dataRes = await this.myApiService.patch(`organizational-unit/enabled/${idOgUnit}`);
          await this.organizationalUnitsService.loadOrganizationalUnits()

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    });
  }

  async enabledOrDisabled() {
    if (this.disabledUnits === true) {
      this.buttonText = "Ver Deshabilitados"
      this.disabledUnits = false
      this.organizationalUnitsService.activeOrganizationalUnits(this.dataSource);
    } else {
      this.buttonText = "Ver Habilitados"
      this.disabledUnits = true
      this.organizationalUnitsService.inactiveOrganizationalUnits(this.dataSource);
    }
  }

  ngOnChanges() {
    this.displayedColumns = [...this.displayedColumns, ...this.iconColumn];
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async ngOnInit() {
    try {
      if (this.disabledUnits === true) {
        this.organizationalUnitsService.inactiveOrganizationalUnits(this.dataSource);
      } else {
        this.organizationalUnitsService.activeOrganizationalUnits(this.dataSource);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
}
