import { createAction, props } from "@ngrx/store";
import { OrganizationalUnit } from "../reducers/organizationalUnit.reducer";

export const loadOrganizationalUnits = createAction("[Organizational Units] Load Organizational Units");

export const loadOrganizationalUnitsSuccess = createAction(
  "[Organizational Units] Load Organizational Units Success",
  props<{ organizationalUnits: OrganizationalUnit[] }>()
);

// export const loadOrganizationalUnitsActive = createAction(
//   "[Organizational Units] Load Organizational Units Actives",
//   props<{ organizationalUnits: OrganizationalUnit[] }>()
// );

// export const addOrganizationalUnit = createAction(
//   "[Organizational Units] Add Organizational Unit",
//   props<{ organizationalUnit: OrganizationalUnit }>()
// );

// export const updateOrganizationalUnit = createAction(
//   "[Organizational Units] Update Organizational Unit",
//   props<{ organizationalUnit: OrganizationalUnit }>()
// );

// export const deleteOrganizationalUnit = createAction(
//   "[Organizational Units] Delete Organizational Unit",
//   props<{ cod: string }>()
// );
