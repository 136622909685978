        <!-- (click)="page.children ? showFiller = !showFiller : showFiller = false" -->

<div class="sidebar" [class.collapsed]="collapsed">
  <img src="../../../assets/images/ucc-isologo.png" alt="Logo ucc" />
  <h3>Biblioteca UCC</h3>

  <div class="sidebar-items">
    <div *ngFor="let page of pages" class="page">
      <div 
        class="sidebar-item"
        routerLink="/{{ page.slug }}"
        routerLinkActive="btn-page-active"
        (click)="page.children && page.view = !page.view"
      >
        <i class="material-icons-outlined">{{ page.icon }}</i>
        <span *ngIf="!collapsed">{{ page.name }}</span>
        <button
          *ngIf="page.children"
          mat-raised-button
          class="expand"
        >
          <i class="material-icons">expand_more</i>
        </button>
      </div>

      <div *ngIf="page.view" class="page-multiple">
        <div 
          *ngFor="let page of page.children" 
          class="sidebar-item children"
          routerLink="/{{ page.slug }}"
          routerLinkActive="btn-page-active"
        >
          <i class="material-icons-outlined children">{{ page.icon }}</i>
          <span>{{ page.name }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
