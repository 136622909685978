import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuickActionsComponent } from 'src/app/shared/components/quick-actions/quick-actions.component';
import { MyApiService } from 'src/services/connection';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, QuickActionsComponent],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass'],
})
export class HomePageComponent {
  title = '¡Hola, te damos la bienvenida!';
  subtitle =
    'Conocé el Sistema de Compras de Biblioteca y comenzá a llevar el seguimiento de tu actividad.';
  quickActions = [
    { title: 'Solicitudes de Compra', icon: 'local_offer', slug: 'solicitudes-de-compra' },
    { title: 'Solicitudes de Cotización', icon: 'wysiwyg', slug: 'solicitudes-de-cotización' },
    { title: 'Ordenes de Compra', icon: 'receipt', slug: 'ordenes-de-compra' },
    { title: 'Facturas', icon: 'description', slug: 'facturas' },
  ];
  actions = [
    { title: 'Solicitudes de Compra', icon: 'add', coloredIcon: true, slug: 'solicitudes-de-compra' },
    { title: 'Productos', icon: 'add', coloredIcon: true, slug: 'productos' },
  ];



  // Inyecta el servicio en tu componente o servicio
  constructor(
    private myApiService: MyApiService
  ) {}

  dataRes: any
  
  // Get Example
  // async ngOnInit() {
  //   try {
  //     const data = await this.myApiService.get('roles');
  //     console.log(data);
  //     this.dataRes = data
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }

  // Post Example
  // async ngOnInit() {
  //   const rol =
  //   {
  //     "name":"Juan"
  //   }

  //   try {
  //     this.dataRes = await this.myApiService.post('product', rol);
  //     console.log(this.dataRes);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }
  
  // Patch Example
  // async ngOnInit() {
  //   const rolEdit =
  //   {
  //     "description":"Crack"
  //   }

  //   try {
  //     this.dataRes = await this.myApiService.patch(`roles/${3}`, rolEdit);
  //     console.log(this.dataRes);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }

  // Delete Example
  // async ngOnInit() {
  //   try {
  //     this.dataRes = await this.myApiService.delete(`roles/${1}`);
  //     console.log(this.dataRes);
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // }

}
