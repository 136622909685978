
<div class="modalContainer">
  <mat-dialog-content class="informationContainer">
    <div *ngIf="icon" class="iconContainer">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4.47012 20.5036H19.5301C21.0701 20.5036 22.0301 18.8336 21.2601 17.5036L13.7301 4.49359C12.9601 3.16359 11.0401 3.16359 10.2701 4.49359L2.74012 17.5036C1.97012 18.8336 2.93012 20.5036 4.47012 20.5036ZM12.0001 13.5036C11.4501 13.5036 11.0001 13.0536 11.0001 12.5036V10.5036C11.0001 9.95359 11.4501 9.50359 12.0001 9.50359C12.5501 9.50359 13.0001 9.95359 13.0001 10.5036V12.5036C13.0001 13.0536 12.5501 13.5036 12.0001 13.5036ZM13.0001 17.5036H11.0001V15.5036H13.0001V17.5036Z" fill="#626262"/>
      </svg>
  </div>
  <div class="titleContainer" >{{title}}</div>
<div class="subtitleContainer">{{subtitle}}<br/>{{subtitleQuestion}}</div>
  </mat-dialog-content>
  <mat-dialog-actions class="buttonContainer">
    <button mat-button (click)="onAcceptButtonClick()" class="acceptButton"> {{ acceptButton }} </button>
    <button mat-button  class="cancelButton"  [mat-dialog-close]="true" cdkFocusInitial>
      {{ cancelButton }}
    </button>
  </mat-dialog-actions>
</div>
