import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { OrganizationalUnit, OrganizationalUnitsState } from "../reducers/organizationalUnit.reducer";

export const selectOrganizationalUnitsState = (state: AppState) =>
  state.organizationalUnit;

export const selectAllOrganizationalUnits = createSelector(
  selectOrganizationalUnitsState,
  (state: OrganizationalUnitsState) => state.organizationalUnits
);

export const selectOrganizationalUnitActives = createSelector(
  selectAllOrganizationalUnits,
  (organizationalUnits: OrganizationalUnit[]) =>
    organizationalUnits.filter((unit) => unit.active == 'true')
);

export const selectOrganizationalUnitInactives = createSelector(
  selectAllOrganizationalUnits,
  (organizationalUnits: OrganizationalUnit[]) =>
    organizationalUnits.filter((unit) => unit.active == 'false')
);
