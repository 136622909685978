import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { CareersModal } from '../careers-modal/careers-modal.component';
import { CareersService } from '../careers.service';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-header-table',
  standalone: true,
  imports: [CommonModule, MatChipsModule, CareersModal, MatIconModule],
  templateUrl: './careers-header.component.html',
  styleUrls: ['./careers-header.component.sass'],
})

export class HeaderTableComponent {
  title: string = 'Carreras';
  chipText: string = 'Nueva Carrera';
  searchText: string = '';
  dialog: any;

  constructor(
    private careersService: CareersService,
  ) {}


  async openCreateCareer() {
    this.careersService.openCreateCareer();
  }

}
