import { createSelector, createFeatureSelector } from '@ngrx/store';

import { Careers, CareersState } from '../reducers/careers.reducer';
import { AppState } from '../app.state';

export const selectCareersState = (state: AppState) =>
  state.careers


export const selectCareers = createSelector(
  selectCareersState,
  (state: CareersState) => state.careers
);

export const selectActiveCareers = createSelector(
  selectCareers,
  (careers: Careers[]) =>
  careers.filter((unit) => unit.active == 'true')
  );

  export const selectInactiveCareers = createSelector(
    selectCareers,
    (careers: Careers[]) =>
  careers.filter((unit) => unit.active == 'false')

);

export const selectCareersLoading = createSelector(
  selectCareersState,
  (state: CareersState) => state.loading
);

export const selectCareersError = createSelector(
  selectCareersState,
  (state: CareersState) => state.error
);
