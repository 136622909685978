import { Injectable } from '@angular/core';
import { MyApiService } from './connection';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PeriodoService {
  periodos: any; // Array para almacenar los periodos

  constructor(
    private myApiService: MyApiService
  ) { }

  async ngOnInit() {
    try {
      this.periodos = await this.myApiService.get('term');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // Método para agregar un nuevo periodo
  async agregarPeriodo(periodo: any): Promise<boolean> {
    await this.ngOnInit()
    // console.log("Periodo Nuevo y Periodos Existentes");
    // console.log(periodo);
    // console.log(this.periodos);
    // console.log("-------------------");
    
    // Validar si el nuevo periodo se solapa con algun periodo futuro
    if (this.periodos.futureTerms.some(p => this.seSolapan(p, periodo))) {
      return false; // Periodo se solapa
    }

    // Validar si el nuevo periodo está contenido en algún periodo futuro
    if (this.periodos.futureTerms.some(p => this.contenidoEnPeriodo(p, periodo))) {
      return false; // Periodo contenido
    }

    // Validar si el periodo se solapa o contiene con el periodo actual 
    if (this.esPeriodoActivo(this.periodos.currentTerm, periodo)) {
      return false; // Periodo solapado con el actual
    }

    // Si no hay conflictos, returonar true
    // console.log("---- Periodo Aprobado ----");
    // console.log(this.periodos);
    
    return true;
  };

  // Método para verificar si dos periodos se solapan
  seSolapan(existTerm: any, newTerm: any): boolean {
    // console.log("Se solapan -------------------");
    // console.log(existTerm);
    // console.log(newTerm);
    const inicio1 = new Date(moment(existTerm.startDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD"));
    const fin1 = new Date(moment(existTerm.endDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD"));
    const inicio2 = new Date(newTerm.startDateTerm);
    const fin2 = new Date(newTerm.endDateTerm);
    
    return (inicio1 <= inicio2 && inicio2 <= fin1) ||
           (inicio1 <= fin2 && fin2 <= fin1) ||
           (inicio2 <= inicio1 && inicio1 <= fin2) ||
           (inicio2 <= fin1 && fin1 <= fin2);
  }

  // Método para verificar si un periodo está contenido en otro
  contenidoEnPeriodo(existTerm: any, newTerm: any): boolean {
    // console.log("Periodo Contenido ----------------");
    // console.log(existTerm);
    // console.log(newTerm);
    const inicio1 = new Date(moment(existTerm.startDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD"));
    const fin1 = new Date(moment(existTerm.endDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD"));
    const inicio2 = new Date(newTerm.startDateTerm);
    const fin2 = new Date(newTerm.endDateTerm);

    return inicio2 >= inicio1 && fin2 <= fin1;
  }

  // Método para verificar si un periodo es activo
  esPeriodoActivo(currentTerm: any, newTerm: any): boolean {
    // console.log("Periodo Activo ------------------");
    // console.log(currentTerm);
    // console.log(newTerm);
    const inicio = new Date(moment(currentTerm.startDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD"));
    const fin = new Date(moment(currentTerm.endDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD"));
    const fechaInicioNuevo = new Date(newTerm.startDateTerm);
    const fechaFinNuevo = new Date(newTerm.endDateTerm);

    return (fechaInicioNuevo >= inicio && fechaInicioNuevo <= fin) ||
           (fechaFinNuevo >= inicio && fechaFinNuevo <= fin);
  }
}