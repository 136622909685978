<div class="modalContainer">
  <h2 class="title" mat-dialog-title>{{title}}</h2>

  <mat-dialog-content class="content">
    <h3 class="label">Titulo</h3>
    <input id="title" 
      [(ngModel)]="name" 
      (ngModelChange)="onInputChange()" 
      class="inputText" 
      type="text" 
      placeholder="Título"
    >
    <hr style="margin-bottom: 10px;"/>
  </mat-dialog-content>

  <mat-dialog-content class="content">
    <h3 class="label-stage">Etapas del periodo</h3>
    
    <div class="stage" *ngFor="let term of dataTerm.timeTerm">
      <div class="stage-container">
        <h3 class="stage-title">{{ term.typeTimeTerm }}</h3>
        
        <div class="calendar">
          <mat-form-field id="startDate-{{ term.id }}">
            <mat-label>Fecha de Inicio</mat-label>
            <input #startDateInput="ngModel"
              id="startDate-{{ term.id }}" 
              matInput 
              [min]="term.minStartDate" 
              [max]="term.maxStartDate" 
              [matDatepicker]="pickerStart" 
              [(ngModel)]="term.startDate" 
              (dateChange)="onValueChange(term, 'startDate')"
            >
            <mat-datepicker-toggle matIconSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart></mat-datepicker>
          </mat-form-field>
          
          <mat-form-field id="endDate-{{ term.id }}">
            <mat-label>Fecha de Fin</mat-label>
            <input 
              #endDateInput
              id="endDate-{{ term.id }}" 
              matInput 
              [min]="term.minEndDate" 
              [matDatepicker]="pickerEnd" 
              [(ngModel)]="term.endDate" 
              (dateChange)="onValueChange(term, 'endDate')"
            >
            <mat-datepicker-toggle matIconSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button 
      mat-button 
      [disabled]="!isAcceptButtonEnabled" 
      class="acceptButton {{ isAcceptButtonEnabled ? 'enabled' : 'disabled' }}" 
      (click)="onAcceptButtonClick()"
    >
      {{ acceptButton }}
    </button>

    <button 
      mat-button 
      [style.width.px]="172" 
      class="cancelButton" 
      (click)="onCancelButtonClick()"
    >
      {{ cancelButton }}
    </button>
  </mat-dialog-actions>
</div>
