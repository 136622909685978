import { Component, OnInit } from '@angular/core';
import { NotSavedModalComponent } from "../../shared/components/not-saved-modal/not-saved-modal.component";
import { EmptyStateComponent } from 'src/app/shared/components/empty-state/empty-state.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MyApiService } from 'src/services/connection';
import { CommonModule } from '@angular/common';
import { CareersTable } from './careers-table/careers-table.component';
import { HeaderTableComponent } from './careers-header/careers-header.component';
import { CareersService } from './careers.service';

@Component({
  standalone: true,
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.sass'],
  imports: [
    NotSavedModalComponent, 
    MatButtonModule, 
    MatDialogModule, 
    EmptyStateComponent, 
    HeaderTableComponent, 
    CommonModule, 
    CareersTable
  ]
})
export class CareersComponent implements OnInit {
    constructor(
      public dialog: MatDialog,
      private careersService: CareersService
    ) {}


  data: any;
  showModal: boolean = false;
  errorCode?: any = null;

  cancelar() {
    const dialogRef = this.dialog.open(NotSavedModalComponent,{
      data: {
        title:"¡Espera un momento!",
        subtitle:"No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos.",
        subtitleQuestion:"¿Deseas salir sin guardar?",
        acceptButton:"Si, descartar",
        cancelButton:"Cancelar",
        icon: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  async ngOnInit() {
    try {
      await this.careersService.loadCareers();
      await this.careersService.findAll().then(units => {
        this.data = units
      });
    } catch (error) {
      this.errorCode = error
      console.error('Error fetching data:', error);
    }
  }
}


