<div class="page-container">
  <div class="left-column">
    <div class="content">
      <img src="../../../assets/images/logo-ucc.png" alt="Logo ucc" />
      <img src="../../../assets/images/thumbnail.png" alt="Thumbnail" />
      <h2 class="left-title">
        Comenzá a usar el Sistema de Compras de Biblioteca
      </h2>
      <h3>Iniciá sesion y gestioná todos tus pedidos.</h3>
      <h3>
        ¿Aún no tenes un Usuario? Contactaté con nosotros <span> AQUÍ </span>
      </h3>
    </div>
  </div>
  <div class="right-column">
    <h2 class="right-title">Biblioteca UCC</h2>
    <login-component></login-component>
  </div>
</div>
