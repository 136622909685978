import { Injectable } from '@angular/core';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MyApiService {
  private apiUrl = environment.apiUrl;

  constructor() {}

  async request(method: string, url: string, data?: any): Promise<any> {
    const config: AxiosRequestConfig = {
      method,
      url: `${this.apiUrl}/${url}`,
      data,
    };

    try {
      const response: AxiosResponse = await axios(config);
      return response.data;
    } catch (error: any) {
      console.log(error.response.status)
      throw error.response.status;
    }
  }

  get(url: string): Promise<any> {
    return this.request('get', url);
  }

  post(url: string, data: any): Promise<any> {
    return this.request('post', url, data);
  }

  patch(url: string, data?: any): Promise<any> {
    return this.request('patch', url, data);
  }


  delete(url: string): Promise<any> {
    return this.request('delete', url);
  }

}