import { Injectable } from '@angular/core';
import { MyApiService } from 'src/services/connection';
import { Store } from '@ngrx/store';
import * as CareersActions from '../../state/actions/careers.actions';
import { AppState } from 'src/app/state/app.state';
import { selectActiveCareers, selectCareers, selectInactiveCareers } from 'src/app/state/selectors/careers.selectors';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CareersModal } from './careers-modal/careers-modal.component';
import { Career } from './careers.interface';
import { BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CareersService {
  constructor(
    private myApiService: MyApiService,
    private store: Store<AppState>,
    public dialog: MatDialog
  ) {}

  private editForm = new BehaviorSubject<boolean>(false);
  editFormServ = this.editForm.asObservable();

  setEditForm(edit: boolean) {
    this.editForm.next(edit);
  }

  getEditForm(): boolean {
    return this.editForm.getValue();
  }

  async loadCareers(): Promise<void> {
    try {
      const data = await this.myApiService.get('careers');
      this.store.dispatch(CareersActions.loadCareersSuccess({ careers: data }));
    } catch (error) {
      console.error('Error fetching careers:', error);
      this.store.dispatch(CareersActions.loadCareersFailure({ error }));
    }
  }

  async findAll(): Promise<any[]> {
    try {
      return new Promise<any[]>((resolve, reject) => {
        this.store.select(selectCareers).subscribe(careers => {
          resolve(careers);
        });
      });
    } catch (error) {
      console.error('Error fetching careers:', error);
      throw error;
    }
  }
  activeCareers(dataSource: MatTableDataSource<any>): void {
    this.store.select(selectActiveCareers).subscribe(careers => {
      dataSource.data = careers;
    });
  }

  inactiveCareers(dataSource: MatTableDataSource<any>): void {
    this.store.select(selectInactiveCareers).subscribe(careers => {
      dataSource.data = careers;
    });
  }

  async openCareerDetail(idCar: number) {
    try {
      const data: Career = await this.myApiService.get(`careers/${idCar}`);
      const dialogRef = this.dialog.open(CareersModal, {
        data: {
          title: `Carrera - Nro ${data.idCar}`,
          name: `${data.name}`,
          description: `${data.description}`,
          category: `${data.career_category}`,
          level: `${data.career_level}`,
          idCar: data.idCar,
          orgUnitId: data.orgUnitId.idOgUnit,
          organizationalUnitName: `${data.orgUnitId.name}`,
          cancelButton: 'Volver',
          inputActive: false,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async openModifyCareer(idCar: number) {
    try {
      const data = await this.myApiService.get(`careers/${idCar}`);
      const rawDataOrganizationalUnits = await this.myApiService.get(`organizational-unit`);
      const dataOrganizationalUnits = rawDataOrganizationalUnits.filter((item: { active: string; }) => item.active === "true");

      const dialogRef = this.dialog.open(CareersModal, {
        data: {
          idCar: `${data.idCar}`,
          title: `Carrera - Nro ${data.idCar}`,
          name: `${data.name}`,
          description: `${data.description}`,
          category: `${data.career_category}`,
          level: `${data.career_level}`,
          organizationalUnitName: `${data.orgUnitId.name}`,
          organizationalUnits: dataOrganizationalUnits,
          acceptButton: 'Guardar Cambios',
          cancelButton: 'Cancelar',
          inputActive: true,
          modify: true,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.loadCareers();
        console.log(`Dialog result: ${result}`);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async openCreateCareer() {
    try {
      const rawDataOrganizationalUnits = await this.myApiService.get(`organizational-unit`);
      const dataOrganizationalUnits = rawDataOrganizationalUnits.filter((item: { active: string; }) => item.active === "true");
      this.dialog.open(CareersModal, {
        data: {
          title: 'Creando Carrera',
          name: '',
          description: '',
          category: '',
          level: '',
          organizationalUnitName: '',
          organizationalUnits: dataOrganizationalUnits,
          acceptButton: 'Crear',
          cancelButton: 'Cancelar',
          inputActive: true
        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

}
