<div class="container">
  <h1>{{ title }}</h1>
  <h3>{{ subtitle }}</h3>
  <div class="content">
    <app-quick-actions
      title="Acciones rápidas"
      subtitle="Gestioná los pendientes aquí"
      [actions]="quickActions"
    ></app-quick-actions>

    <app-quick-actions
      title="Iniciá aquí"
      subtitle="Registrá tus solicitudes y productos"
      [actions]="actions"
      [singleRow]="true"
    ></app-quick-actions>
  </div>
</div>
