import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { OrganizationalUnitModal } from 'src/app/pages/organizational-units/organizational-unit-modal/organizational-unit-modal.component';


@Component({
  selector: 'term-header',
  standalone: true,
  imports: [CommonModule, MatChipsModule, OrganizationalUnitModal],
  templateUrl: './term-header.component.html',
  styleUrls: ['./term-header.component.sass'],
})

export class TermHeader {
  @Input() title: string = 'Administración de Periodos';
  @Input() chipText: string = 'Nuevo Periodo';
  searchText: string = '';
  dialog: any;

  @Output() openCreateOrganizationalUnit = new EventEmitter<void>();

  onOpenCreateOrganizationalUnit() {
    this.openCreateOrganizationalUnit.emit();
  }
}
