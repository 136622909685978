import { LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  Input,
  AfterViewInit,
  Component,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { EmptyStateComponent } from '../../components/empty-state/empty-state.component';
import { MyApiService } from 'src/services/connection';

/**
 * @title Table with pagination
 */
@Component({
  selector: 'table-term',
  styleUrls: ['table-term.component.sass'],
  templateUrl: 'table-term.component.html',
  standalone: true,
  imports: [
    FormsModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    EmptyStateComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TablePaginationExample<T> implements AfterViewInit {
  data: any[] = [];
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private myApiService: MyApiService
  ) {}
  @ViewChild(MatSort)
  sort!: MatSort;

  timeTermData!: TimeTerm[];
  displayedColumns: string[] = ['id', 'name', 'startDateTerm', 'endDateTerm'];
  dataSource = new MatTableDataSource<TimeTerm>(this.timeTermData);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  ngOnChanges() {
    this.displayedColumns = [...this.displayedColumns];
  }
  // --------------------
  async ngOnInit() {
    try {
      this.timeTermData = await this.myApiService.get('term/history');
      console.log(this.timeTermData);
      this.dataSource = new MatTableDataSource<TimeTerm>(this.timeTermData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  ngAfterViewInit() {
    console.log(this.timeTermData);
    this.displayedColumns = [...this.displayedColumns];
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getColumnHeader(column: string): string {
    switch (column) {
      case 'startDateTerm':
        return 'Inicio';
      case 'endDateTerm':
        return 'Finalización';
      case 'id':
        return 'Nro Periodo';
      case 'name':
        return 'Título';
      default:
        return '';
    }
  }

  formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }

  // Sort
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}

interface TimeTerm {
  id: number;
  startDateTerm: Date;
  endDateTerm: Date;
  name: string;
}


