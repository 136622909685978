import { createReducer, on } from "@ngrx/store";
import { loadOrganizationalUnits, loadOrganizationalUnitsSuccess } from "../actions/organizationalUnit.actions";


export interface OrganizationalUnitsState {
  organizationalUnits: OrganizationalUnit[];
}

export interface OrganizationalUnit {
  cod: string;
  name: string;
  description: string;
  active: string
}

export const initialState: OrganizationalUnitsState = {
  organizationalUnits: [],
};

export const organizationalUnitsReducer = createReducer(
  initialState,
  on(loadOrganizationalUnits, (state) => ({
    ...state,
  })),
  on(loadOrganizationalUnitsSuccess, (state, { organizationalUnits }) => ({
    ...state,
    organizationalUnits,
  })),
  // on(addOrganizationalUnit, (state, { organizationalUnit }) => ({
  //   ...state,
  //   organizationalUnits: [...state.organizationalUnits, organizationalUnit],
  // })),
  // on(updateOrganizationalUnit, (state, { organizationalUnit }) => ({
  //   ...state,
  //   organizationalUnits: state.organizationalUnits.map((unit) =>
  //     unit.cod === organizationalUnit.cod ? organizationalUnit : unit
  //   ),
  // })),
  // on(deleteOrganizationalUnit, (state, { cod }) => ({
  //   ...state,
  //   organizationalUnits: state.organizationalUnits.filter((unit) => unit.cod !== cod),
  // })),
);
