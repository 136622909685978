import { ActionReducer, MetaReducer } from "@ngrx/store";
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppState } from './app.state';

export const sessionStorageSyncReducer = (
    keys: string[],
  ): MetaReducer<AppState, any> => {
    return (reducer: ActionReducer<AppState, any>) => {
      return localStorageSync({ keys, rehydrate: true, storage: sessionStorage })(reducer);
    };
  };