import { createReducer, on } from '@ngrx/store';
import * as CareersActions from '../actions/careers.actions';


export interface CareersState {
  careers: Careers[];
  loading: boolean;
  error: any;
}

export interface Careers {
  cod: string;
  name: string;
  description: string;
  active: string;
  career_level: string;
  career_category: string;
  orgUnitId : number;
}

export const initialCareersState: CareersState = {
  careers: [],
  loading: false,
  error: null
};


export const careersReducer = createReducer(
  initialCareersState,
  on(CareersActions.loadCareers, state => ({ ...state, loading: true })),
  on(CareersActions.loadCareersSuccess, (state, { careers }) => ({ ...state, careers, loading: false })),
  on(CareersActions.loadCareersFailure, (state, { error }) => ({ ...state, error, loading: false }))
);
