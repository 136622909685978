import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MyApiService } from 'src/services/connection';
import { MatIconModule } from '@angular/material/icon';
import { EmptyStateComponent } from '../../components/empty-state/empty-state.component';


interface TimeTerm {
  id: number;
  startDate: Date;
  endDate: Date;
  typeName: string;
}

interface Term {
  name: string;
  description: string;
  active: boolean;
  startDateTerm: Date;
  endDateTerm: Date;
  timeTerm?: TimeTerm[] | undefined;
}

@Component({
  selector: 'app-summary-term',
  templateUrl: './summary-term.component.html',
  styleUrls: ['./summary-term.component.sass'],
  imports: [CommonModule, MatChipsModule, MatIconModule,  EmptyStateComponent,],
  standalone: true,
})
export class SummaryTermComponent {
  constructor(private myApiService: MyApiService) {}

  currentPeriod?: Term | null = null;
  errorCode?: any = null;

  formatDate(date: Date): string {
    return date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });
  }

  async ngOnInit() {
    try {
      this.currentPeriod = await this.myApiService.get('term/current');
    } catch (error: any) {
      this.errorCode = error
      console.log('Error fetching data:', error);
    }
  }

  onEdit(): void {
    console.log('Editar');
    // Add your edit logic here
  }
}