<ng-container *ngIf="isLogged; else login">
  <mat-drawer-container class="container" autosize>
    <mat-drawer #drawer class="sidenav" mode="side" opened>
      <app-sidebar></app-sidebar>
    </mat-drawer>
    
    <div class="content">
      <div class="navbar">
        <button type="button" mat-button (click)="drawer.toggle()">
          <i class="material-icons">menu</i>
        </button>
        <app-navbar></app-navbar>
      </div>

      <div class="router">
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-drawer-container>
</ng-container>
<ng-template #login>
  <router-outlet></router-outlet>
</ng-template>