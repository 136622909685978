
<app-header-table></app-header-table>
<ng-container *ngIf="data !== null && data?.length > 0">
  <organizationalUnit-table class="table-containers"></organizationalUnit-table>
</ng-container>

<ng-container *ngIf="data !== null && data?.length === 0">
  <app-empty-state [errorCode]="errorCode"></app-empty-state>
</ng-container>



