<div class="modalContainer">
  <h2 class="title" mat-dialog-title>{{ title }}</h2>
  <mat-dialog-content class="content">
    <div *ngIf="inputActive; else noInputContent">
      <h3 class="inputActive">Titulo</h3>
      <input
        [(ngModel)]="name"
        (ngModelChange)="onInputChange()"
        class="inputText"
        type="text"
        placeholder="Título"
      />
      <hr style="margin-bottom: 15px" />
      <h3 class="inputActive">Descripción</h3>
      <input
        [(ngModel)]="description"
        (ngModelChange)="onInputChange()"
        class="inputDescription"
        type="text"
        placeholder="Descrpción"
      />
      <hr style="margin-bottom: 15px" />
      <h3 class="inputActive">Categoría de Carrera</h3>
      <input
        [(ngModel)]="category"
        (ngModelChange)="onInputChange()"
        class="inputDescription"
        type="text"
        placeholder="Categoría"
      />
      <hr style="margin-bottom: 15px" />
      <h3 class="inputActive">Nivel de Carrera</h3>
      <mat-select
        [(ngModel)]="level"
        (ngModelChange)="onInputChange()"
        class="inputDescription"
        placeholder="Nivel"
      >
        <mat-option value="Pregrado">Pregrado</mat-option>
        <mat-option value="Grado">Grado</mat-option>
        <mat-option value="Posgrado">Posgrado</mat-option>
      </mat-select>
      <hr style="margin-bottom: 15px" />
      <h3 class="inputActive">Unidad Organizacional</h3>
      <mat-select
        [(ngModel)]="organizationalUnitName"
        (ngModelChange)="onInputChange()"
        class="inputDescription"
        placeholder="Unidad organizacional"
      >
        <mat-option
          *ngFor="let unit of organizationalUnits"
          [value]="unit.name"
          >{{ unit.name }}</mat-option
        >
      </mat-select>
      <hr style="margin-bottom: 3px" />
    </div>
    <ng-template #noInputContent>
      <div style="display: flex">
        <p class="inputText">{{ name }}</p>
        <button
          (click)="editCareer(idCar)"
          style="
            outline: none;
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 16L4 20L8 19L19.5858 7.41421C20.3668 6.63316 20.3668 5.36683 19.5858 4.58579L19.4142 4.41421C18.6332 3.63316 17.3668 3.63317 16.5858 4.41421L5 16Z"
              stroke="#292929"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15 6L18 9"
              stroke="#292929"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13 20H21"
              stroke="#292929"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <h3 class="inputActive">Descripción</h3>
      <p class="inputDescription">{{ description }}</p>
      <h3 class="inputActive">Categoría de Carrera</h3>
      <p class="inputDescription">{{ category }}</p>
      <h3 class="inputActive">Nivel de Carrera</h3>
      <p class="inputDescription">{{ level }}</p>
      <h3 class="inputActive">Unidad Organizacional</h3>
      <p class="inputDescription">{{ organizationalUnitName }}</p>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button
      *ngIf="inputActive"
      mat-button
      mat-dialog-close="{{ title }}"
      [disabled]="!isAcceptButtonEnabled"
      class="acceptButton {{ isAcceptButtonEnabled ? 'enabled' : 'disabled' }}"
      (click)="onCreateButtonClick(modify)"
    >
      {{ acceptButton }}
    </button>

    <button
      mat-button
      [style.width.px]="inputActive ? 172 : 140"
      class="cancelButton"
      (click)="onCancelButtonClick()"
    >
      <div style="display: flex; align-items: center">
        <mat-icon
          *ngIf="!inputActive"
          aria-hidden="false"
          style="transform: scaleX(-1)"
          fontIcon="start"
        ></mat-icon>
        <span style="padding-left: 5px">
          {{ cancelButton }}
        </span>
      </div>
    </button>
  </mat-dialog-actions>
</div>
