import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, Inject  } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@Component({
  standalone:true,
  imports: [MatIconModule, CommonModule, MatDialogModule, MatButtonModule],
  selector: 'app-not-saved-modal',
  templateUrl: './not-saved-modal.component.html',
  styleUrls: ['./not-saved-modal.component.sass']
})

export class NotSavedModalComponent {
  constructor(
    private dialogRef: MatDialogRef<NotSavedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

  // cancel() {
  //   window.location.href = '/login';
  // }

  title:string ="¡Espera un momento!"
  subtitle:string ="No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos."
  subtitleQuestion:string ="¿Deseas salir sin guardar?"
  acceptButton:string="Si, descartar"
  cancelButton:string="Cancelar"
  icon:boolean= true

  ngOnInit() {
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.subtitle = this.data.subtitle || '';
    this.subtitleQuestion = this.data.subtitleQuestion || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.icon = this.data.icon || false;

  }


  onAcceptButtonClick(): void {
    this.dialogRef.close('accept');
  }

  onCancelButtonClick(): void {
    this.dialogRef.close(true);
  }

}


