import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import * as moment from 'moment';

import { TermModalComponent } from 'src/app/shared/term/term-modal/term-modal.component';
import { NotSavedModalComponent } from "../../shared/components/not-saved-modal/not-saved-modal.component";
import { EmptyStateComponent } from 'src/app/shared/components/empty-state/empty-state.component';
import { TablePaginationExample } from 'src/app/shared/term/table-term/table-term.component';
import { SummaryTermComponent } from 'src/app/shared/term/summary-term/summary-term.component';
import { TermHeader } from 'src/app/shared/term/term-header/term-header.component';


@Component({
  standalone: true,
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.sass'],
  imports: [NotSavedModalComponent, MatButtonModule, MatDialogModule, EmptyStateComponent,TablePaginationExample, SummaryTermComponent, TermHeader]
})
export class TermComponent {

  constructor(
    public dialog: MatDialog
  ) {}
  
  openCreateTerm() {
    const dialogRef = this.dialog.open(TermModalComponent, {
      data: {
        title: 'Nuevo Periodo de Compra',
        acceptButton: 'Crear Periodo',
        cancelButton: 'Cancelar',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openModifyTerm() {
    const dialogRef = this.dialog.open(TermModalComponent, {
      data: {
        title: 'Modificar Periodo de Compra',
        name: "Periodo 2",
        acceptButton: 'Guardar Cambios',
        cancelButton: 'Cancelar',
        modifyTerm: true,
        dataTerm: {
          id: 4,
          name: "Periodo 2",
          description: "string",
          active: "true",
          timeTerm: [
            {
              startDate: moment("11/03/2024", "DD/MM/YYYY"),
              endDate: moment("15/03/2024", "DD/MM/YYYY"),
              orderTime: 2,
              id: 2,
              minStartDate: moment("11/03/2024", "DD/MM/YYYY"),
              minEndDate: moment("11/03/2024", "DD/MM/YYYY"),
              maxStartDate: moment("11/03/2024", "DD/MM/YYYY"),
              typeTimeTerm: "Control y Aprobación de la Solicitud de Compra"
            },
            {
              startDate: moment("16/03/2024", "DD/MM/YYYY"),
              endDate: moment("20/03/2024", "DD/MM/YYYY"),
              orderTime: 3,
              id: 3,
              minStartDate: moment("16/03/2024", "DD/MM/YYYY"),
              minEndDate: moment("16/03/2024", "DD/MM/YYYY"),
              maxStartDate: moment("16/03/2024", "DD/MM/YYYY"),
              typeTimeTerm: "Convocatoria de Cotización"
            },
            {
              startDate: moment("01/03/2024", "DD/MM/YYYY"),
              endDate: moment("10/03/2024", "DD/MM/YYYY"),
              orderTime: 1,
              id: 1,
              minStartDate: "",
              minEndDate: moment("01/03/2024", "DD/MM/YYYY"),
              maxStartDate: "",
              typeTimeTerm: "Convocatoria de Carga"
            },
            {
              startDate: moment("21/03/2024", "DD/MM/YYYY"),
              endDate: moment("25/03/2024", "DD/MM/YYYY"),
              orderTime: 4,
              id: 4,
              minStartDate: moment("21/03/2024", "DD/MM/YYYY"),
              minEndDate: moment("21/03/2024", "DD/MM/YYYY"),
              maxStartDate: moment("21/03/2024", "DD/MM/YYYY"),
              typeTimeTerm: "Adjudicación y Orden de Compra"
            }
          ]
        }
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
