import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { IconButtonComponent } from '../icon-button/icon-button.component';

@Component({
  selector: 'app-quick-actions',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    IconButtonComponent,
  ],
  templateUrl: './quick-actions.component.html',
  styleUrls: ['./quick-actions.component.sass'],
  // encapsulation: ViewEncapsulation.None,
})
export class QuickActionsComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() actions: any[] = [];
  @Input() singleRow: boolean = false;
}