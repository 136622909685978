import { Injectable } from '@angular/core';
import { MyApiService } from 'src/services/connection';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import * as OrganizationalUnit from '../../state/actions/organizationalUnit.actions';
import { selectAllOrganizationalUnits, selectOrganizationalUnitActives, selectOrganizationalUnitInactives } from 'src/app/state/selectors/organizationalUnit.selectors';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { OrganizationalUnitModal } from './organizational-unit-modal/organizational-unit-modal.component';

@Injectable({
  providedIn: 'root'
})

export class OrganizationalUnits {
  constructor(
    private myApiService: MyApiService,
    private store: Store<AppState>,
    public dialog: MatDialog
  ) {}

  private editForm = new BehaviorSubject<boolean>(false);

  setEditForm(edit: boolean) {
    this.editForm.next(edit);
  }

  getEditForm(): boolean {
    return this.editForm.getValue();
  }

  async loadOrganizationalUnits(): Promise<void> {
    try {
      const data = await this.myApiService.get('organizational-unit');
      this.store.dispatch(OrganizationalUnit.loadOrganizationalUnitsSuccess({ organizationalUnits: data }));
    } catch (error) {
      console.error('Error fetching careers:', error);
    }
  }

  async findAll(): Promise<any[]> {
    try {
      return new Promise<any[]>((resolve, reject) => {
        this.store.select(selectAllOrganizationalUnits).subscribe(organizationalUnits => {

          resolve(organizationalUnits);
        });
      });
    } catch (error) {
      console.error('Error fetching careers:', error);
      throw error;
    }
  }

  activeOrganizationalUnits(dataSource: MatTableDataSource<any>): void {
    this.store.select(selectOrganizationalUnitActives).subscribe(organizationalUnits => {
      dataSource.data = organizationalUnits;
    });
  }

  inactiveOrganizationalUnits(dataSource: MatTableDataSource<any>): void {
    this.store.select(selectOrganizationalUnitInactives).subscribe(organizationalUnits => {
      dataSource.data = organizationalUnits;
    });
  }

  async openOrganizationalUnitDetail(idOgUnit: number) {
    try {
      const data = await this.myApiService.get(`organizational-unit/${idOgUnit}`);
      const dialogRef = this.dialog.open(OrganizationalUnitModal, {
        data: {
          idOgUnit: `${data.idOgUnit}`,
          title: `Unidad Organizacional - Nro ${data.idOgUnit}`,
          name: `${data.name}`,
          description: `${data.description}`,
          cancelButton: 'Volver',
          code: `${data.cod}`,
          saveButton: 'Guardar',
          inputActive: false,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async openModifyOrganizationalUnit(idOgUnit: number) {
    try {
      const data = await this.myApiService.get(`organizational-unit/${idOgUnit}`);
      this.dialog.open(OrganizationalUnitModal, {
          data: {
            idOgUnit: `${data.idOgUnit}`,
            title: `Unidad Organizacional - Nro ${data.idOgUnit}`,
            name: `${data.name}`,
            description: `${data.description}`,
            acceptButton: 'Guardar Cambios',
            code: `${data.cod}`,
            cancelButton: 'Cancelar',
            inputActive: true,
            id: `${data.idOgUnit}`,
            modify: true,
          },
        },
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  openCreateOrganizationalUnit() {
    const dialogRef = this.dialog.open(OrganizationalUnitModal, {
      data: {
        title: 'Creando Unidad Organizacional',
        name: '',
        description: '',
        acceptButton: 'Crear',
        cancelButton: 'Cancelar',
        inputActive: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
