import { CommonModule } from '@angular/common';
import { NgModule, isDevMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ROOT_REDUCERS } from './state/app.state';
import { sessionStorageSyncReducer } from './state/persistence';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';

import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';

import { LoginPageComponent } from './pages/login/login.component';
import { OrganizationalUnitsComponent } from './pages/organizational-units/organizational-units.component';

import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';
import { NavbarComponent } from 'src/app/shared/components/navbar/navbar.component';

import { HeaderTableComponent } from 'src/app/pages/organizational-units/organizational-unit-header/organizational-unit-header.component';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatTableModule} from '@angular/material/table';


@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(ROOT_REDUCERS,
      {
        metaReducers: [sessionStorageSyncReducer(['test', 'auth', 'organizationalUnit', 'careers'])]
      }),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    SharedModule,
    LoginPageComponent,
    CommonModule,
    MatSidenavModule,
    NavbarComponent,
    SidebarComponent,
    MatButtonModule,
    OrganizationalUnitsComponent,
    MatInputModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderTableComponent,
    MatNativeDateModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatTableModule,
    MatIconModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }, // Lenguaje
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }, // Opcional: usar UTC
  ],
})
export class AppModule {}
