import { Component } from '@angular/core';
import { LoginComponent } from '../../shared/components/login/login.component';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [LoginComponent],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginPageComponent {}
