import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { OrganizationalUnitModal } from '../../pages/organizational-units/organizational-unit-modal/organizational-unit-modal.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';

@NgModule({
  imports: [CommonModule, MatIconModule, OrganizationalUnitModal, EmptyStateComponent],
  declarations: [

  ],
})
export class ComponentsModule {}
