<div class="header-container">
  <div class="header-title">
    <h2>{{ title }}</h2>
  </div>
  <div class="header-elements">
    <div class="left-content">
      <mat-chip   class="mat-chip"  [class.pointer-on-hover]="true">
        <div class="chip-content">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
            <path d="M13.5 10.25H9.75V14C9.75 14.4125 9.4125 14.75 9 14.75C8.5875 14.75 8.25 14.4125 8.25 14V10.25H4.5C4.0875 10.25 3.75 9.9125 3.75 9.5C3.75 9.0875 4.0875 8.75 4.5 8.75H8.25V5C8.25 4.5875 8.5875 4.25 9 4.25C9.4125 4.25 9.75 4.5875 9.75 5V8.75H13.5C13.9125 8.75 14.25 9.0875 14.25 9.5C14.25 9.9125 13.9125 10.25 13.5 10.25Z" fill="#fff"/>
          </svg>
          <button class="chip-text" (click)="onOpenCreateOrganizationalUnit()">{{ chipText }}</button>
        </div>
      </mat-chip>
    </div>
    <div class="right-content">
      <div class="header-input">
        <span class="search-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
            <path d="M10.5033 10.0033H9.97659L9.78992 9.82332C10.4433 9.06332 10.8366 8.07665 10.8366 7.00332C10.8366 4.60998 8.89659 2.66998 6.50326 2.66998C4.10992 2.66998 2.16992 4.60998 2.16992 7.00332C2.16992 9.39665 4.10992 11.3366 6.50326 11.3366C7.57659 11.3366 8.56326 10.9433 9.32326 10.29L9.50326 10.4766V11.0033L12.8366 14.33L13.8299 13.3366L10.5033 10.0033ZM6.50326 10.0033C4.84326 10.0033 3.50326 8.66332 3.50326 7.00332C3.50326 5.34332 4.84326 4.00332 6.50326 4.00332C8.16326 4.00332 9.50326 5.34332 9.50326 7.00332C9.50326 8.66332 8.16326 10.0033 6.50326 10.0033Z" fill="black"/>
          </svg>
        </span>
        <input type="text" id="search-input" name="search-input" placeholder="Buscar">
      </div>
    </div>
  </div>
  <hr class="divider" />
</div>
