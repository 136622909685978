import { Component, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, RouterModule, MatMenuModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
  @Output() toggleSidebar = new EventEmitter<any>();

  // Estos valores deben ser proporcionados por el servicio de autenticación o almacenamiento de estado
  userName = 'Usuario UX UI';
  cuil = '20-33333333-0';

  onToggleSidebar() {
    this.toggleSidebar.emit(); // Emite el evento correctamente
  }
}
