import { LiveAnnouncer } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialog } from '@angular/material/dialog';
import { NotSavedModalComponent } from 'src/app/shared/components/not-saved-modal/not-saved-modal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MyApiService } from 'src/services/connection';
import { CareersModal } from '../careers-modal/careers-modal.component';
import { CareersService } from '../careers.service';
import { Store} from '@ngrx/store';
import { CareersState } from 'src/app/state/reducers/careers.reducer';
import { MatTooltipModule } from '@angular/material/tooltip';


@Component({
  selector: 'careers-table',
  styleUrls: ['careers-table.component.sass'],
  templateUrl: 'careers-table.component.html',
  standalone: true,
  imports: [
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatCheckboxModule,
    MatTooltipModule
  ],
})
export class CareersTable<T> implements AfterViewInit {

  constructor(
    private myApiService: MyApiService,
    private _liveAnnouncer: LiveAnnouncer,
    public dialog: MatDialog,
    private careersService: CareersService,
  ) {}

  @ViewChild(CareersModal) careersModal!: CareersModal;

  @ViewChild(MatSort)
  sort!: MatSort;

  // iconColumn: string[] = ['Acciones'];
  // isChecked: boolean = false;
  // mainColumnTitle: string = 'idCar';
  // displayedColumns: string[] = ['idCar', 'name', 'Acciones'];
  // columnsToShow: string[] = ['clickableColumn', 'name', 'editButtons'];
  // dataSource = new MatTableDataSource<any>();
  // selectedRowIndex: number = -1;
  // dataRes: any

  // //allComplete: boolean = false;
  // iconColumn: string[] = ['Acciones'];
  // //chosen: string[] = ['Numero Organizacional'];
  // isChecked: boolean = false;
  // // Array
  // mainColumnTitle: string = 'Nro.';
  // displayedColumns: string[] = ['idCar', 'name', 'Acciones'];
  // columnsToShow: string[] = ['clickableColumn', 'name', 'editButtons'];
  // dataSource = new MatTableDataSource<any>();
  // selectedRowIndex: number = -1;
  // dataRes: any;


  iconColumn: string[] = ['Acciones'];

  isChecked: boolean = false;

  mainColumnTitle: string = 'idCar';
  displayedColumns: string[] = ['idCar', 'name', 'Acciones'];
  columnsToShow: string[] = ['idCar', 'name', 'editButtons'];
  dataSource = new MatTableDataSource<any>();
  selectedRowIndex: number = -1;
  dataRes: any
  allData:any
  disabledCareers: boolean = false
  buttonText: string = 'Ver Deshabilitadas';



  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  checkboxStateMap: { [key: number]: boolean } = {};

  async ngOnInit() {
    try {
      if (this.disabledCareers === true) {
        this.careersService.inactiveCareers(this.dataSource);
      } else {
        this.careersService.activeCareers(this.dataSource);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


async openCareerDetail(idCar: number) {
  this.careersService.openCareerDetail(idCar);
}

async openModifyCareer(idCar: number) {
  this.careersService.setEditForm(false);
  this.careersService.openModifyCareer(idCar);
}

  setSelectedRow(element: any): void {
    this.selectedRowIndex = this.dataSource.data.indexOf(element);
  }


  deleteCareer(idCar: number) {
    const dialogRef = this.dialog.open(NotSavedModalComponent, {
      data: {
        title: 'Eliminando Carrera',
        subtitle: 'Estás por eliminar una Carrera.',
        subtitleQuestion: '¿Deseas continuar?',
        acceptButton: 'Confirmar',
        cancelButton: 'Cancelar',
        icon: false,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result === "accept") {
         try {
          this.dataRes = await this.myApiService.patch(`careers/disable/${idCar}`);
          this.careersService.loadCareers();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
      }
      console.log(`Dialog result: ${result}`);
    });
  }


  async enabledCareer(idCar: number) {
    const dialogRef = this.dialog.open(NotSavedModalComponent, {
      data: {
        title: 'Habilitando Carrera',
        subtitle: 'Estás por habilitar una Carrera.',
        subtitleQuestion: '¿Deseas continuar?',
        acceptButton: 'Confirmar',
        cancelButton: 'Cancelar',
        icon: false,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if(result === "accept") {
        try {
          this.dataRes = await this.myApiService.patch(`careers/enabled/${idCar}`);
          await this.careersService.loadCareers()
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    });
  }

  async enabledOrDisabled() {
    if (this.disabledCareers === true) {
      this.buttonText = "Ver Deshabilitados"
      this.disabledCareers = false
      this.careersService.activeCareers(this.dataSource);
    } else {
      this.buttonText = "Ver Habilitados"
      this.disabledCareers = true
      this.careersService.inactiveCareers(this.dataSource)

    }
  }


  ngOnChanges() {
    this.displayedColumns = [...this.displayedColumns, ...this.iconColumn];
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
