import { OrganizationalUnitModal } from './organizational-unit-modal/organizational-unit-modal.component';
import { Component } from '@angular/core';
import { NotSavedModalComponent } from "../../shared/components/not-saved-modal/not-saved-modal.component";
import { EmptyStateComponent } from 'src/app/shared/components/empty-state/empty-state.component';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { HeaderTableComponent } from 'src/app/pages/organizational-units/organizational-unit-header/organizational-unit-header.component';
import { OrganizationalUnitTable } from './organizational-unit-table/organizationalUnit-table.component';
import { CommonModule } from '@angular/common';
import { OrganizationalUnits } from './organizational-unit.service';


@Component({
    standalone: true,
    selector: 'app-organizational-units',
    templateUrl: './organizational-units.component.html',
    styleUrls: ['./organizational-units.component.sass'],
    imports: [
      NotSavedModalComponent, 
      MatButtonModule, 
      MatDialogModule, 
      EmptyStateComponent, 
      HeaderTableComponent, 
      OrganizationalUnitTable, 
      CommonModule
    ]
})
export class OrganizationalUnitsComponent {
  constructor(
    public dialog: MatDialog,
    private organizationalUnitsService: OrganizationalUnits
  ) {}

  data: any;
  showModal: boolean = false;
  errorCode?: any = null;

  cancelar() {
    const dialogRef = this.dialog.open(NotSavedModalComponent,{
      data: {
        title:"¡Espera un momento!",
        subtitle:"No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos.",
        subtitleQuestion:"¿Deseas salir sin guardar?",
        acceptButton:"Si, descartar",
        cancelButton:"Cancelar",
        icon: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  async ngOnInit() {
    try {
      await this.organizationalUnitsService.loadOrganizationalUnits()
      await this.organizationalUnitsService.findAll().then(units => {
        this.data = units
      });
    } catch (error) {
      this.errorCode = error
      console.error('Error fetching data:', error);
    }
  }
}


