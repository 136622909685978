
<div  *ngIf="dataSource.data.length != 0">
  <div class="table-name">Periodos</div>
  <div class="dynamic-table mat-elevation-z8">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="announceSortChange($event)"
    >
      <ng-container
        *ngFor="let column of displayedColumns"
        [matColumnDef]="column"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ getColumnHeader(column) }}
        </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="column !== 'id'; else idColumn">{{
            element[column]
          }}</span>
          <ng-template #idColumn>
            <span>{{ element.id }}</span>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    ></mat-paginator>
  </div>
</div>

