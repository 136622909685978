
import { Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NotSavedModalComponent } from '../../../shared/components/not-saved-modal/not-saved-modal.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyApiService } from 'src/services/connection';
import { OrganizationalUnits } from 'src/app/pages/organizational-units/organizational-unit.service';

@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './organizational-unit-modal.component.html',
  standalone: true,
  styleUrls: ['./organizational-unit-modal.component.sass'],
  imports: [MatDialogModule, MatButtonModule,FormsModule, CommonModule],
})
export class OrganizationalUnitModal {

  notSavedDialogRef: MatDialogRef<NotSavedModalComponent> | undefined;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<OrganizationalUnitModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private myApiService: MyApiService,
    private organizationalUnitsService: OrganizationalUnits
    ) {}

    dataRes: any
    errorCode?: any = null;

    onCancelButtonClick(): void {
      if (this.inputActive && this.isAcceptButtonEnabled) {
        this.notSavedDialogRef = this.dialog.open(NotSavedModalComponent, {
          data: {
            title:"¡Espera un momento!",
            subtitle:"No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos.",
            subtitleQuestion:"¿Deseas salir sin guardar?",
            acceptButton:"Si, descartar",
            cancelButton:"Cancelar",
            icon: true,

          }
        });
        this.notSavedDialogRef.afterClosed().subscribe(result => {
          console.log(`NotSavedModal result: ${result}`);
          if (result === 'accept') {
            this.dialog.closeAll()
            if (this.organizationalUnitsService.getEditForm()) {
              this.organizationalUnitsService.openOrganizationalUnitDetail(this.idOgUnit)
              this.organizationalUnitsService.setEditForm(false);
            }
          }
        });
      } else if (this.inputActive && this.organizationalUnitsService.getEditForm()) {
        this.dialogRef.close()
        this.organizationalUnitsService.openOrganizationalUnitDetail(this.idOgUnit)
        this.organizationalUnitsService.setEditForm(false);
      } else {
        this.dialogRef.close()
      }
    }

    async onCreateButtonClick(modify: boolean) {
      const dataOU = {
        name: this.name,
        cod: this.code,
        description: this.description,
      };
      if (modify ) {
        try {
          this.dataRes = await this.myApiService.patch(
            `organizational-unit/update/${this.data.id}`,
            dataOU
          );
          this.organizationalUnitsService.openOrganizationalUnitDetail(this.idOgUnit)
          this.organizationalUnitsService.setEditForm(false)
        } catch (error: any) {
          this.errorCode = error
          console.error('Error fetching data:', error);
        }
      } else {
        try {
          this.dataRes = await this.myApiService.post(
            'organizational-unit',
            dataOU
          );
        } catch (error: any) {
          this.errorCode = error
          console.error('Error fetching data:', error);
        }
      }
      await this.organizationalUnitsService.loadOrganizationalUnits()
    }


  editOrganizationalUnit(idOgUnit:number): void {
    this.organizationalUnitsService.setEditForm(true)
    this.organizationalUnitsService.openModifyOrganizationalUnit(idOgUnit)
    this.dialogRef.close()
  }

  title:string =""
  idOgUnit: number = 0;
  name:string =""
  code:string = ""
  description:string =""
  isAcceptButtonEnabled: boolean =  false;
  inputActive:boolean = false;
  acceptButton:string=""
  cancelButton:string=""
  editFromCard:boolean= false;
  modify: boolean = false;

  ngOnInit() {
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.name = this.data.name || '';
    this.description = this.data.description || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.code = this.data.code || '';
    this.inputActive = this.data.inputActive || false;
    this.isAcceptButtonEnabled = false;
    this.editFromCard = this.data.editFromCard || false;
    this.modify = this.data.modify || false;
    this.idOgUnit = this.data.idOgUnit || 0;
  }

  onInputChange() {
    this.updateAcceptButtonState();
  }


  private updateAcceptButtonState() {
    this.isAcceptButtonEnabled = !!this.name && !!this.description;
  }
}
