
import {Component} from '@angular/core';
import {MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { NotSavedModalComponent } from '../../../shared/components/not-saved-modal/not-saved-modal.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyApiService } from 'src/services/connection';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { CareersService } from '../careers.service';


@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: './careers-modal.component.html',
  standalone: true,
  styleUrls: ['./careers-modal.component.sass'],
  imports: [MatDialogModule, MatButtonModule,FormsModule, CommonModule, MatSelectModule, MatOptionModule, MatIconModule],
})
export class CareersModal {

  notSavedDialogRef: MatDialogRef<NotSavedModalComponent> | undefined;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CareersModal>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private myApiService: MyApiService,
    private careersService: CareersService,
  ) {}

    dataRes: any
    errorCode?: any = null;

    onCancelButtonClick(): void {
      if (this.inputActive && this.isAcceptButtonEnabled) {
        this.notSavedDialogRef = this.dialog.open(NotSavedModalComponent, {
          data: {
            title:"¡Espera un momento!",
            subtitle:"No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos.",
            subtitleQuestion:"¿Deseas salir sin guardar?",
            acceptButton:"Si, descartar",
            cancelButton:"Cancelar",
            icon: true,
          }
        });
        this.notSavedDialogRef.afterClosed().subscribe(result => {
          console.log(`NotSavedModal result: ${result}`);
          if (result === 'accept') {
             this.dialog.closeAll()
            if (this.careersService.getEditForm()) {
              this.careersService.openCareerDetail(this.idCar)
              this.careersService.setEditForm(false);
            }
          }
        });
      } else if (this.inputActive && this.careersService.getEditForm()) {
        this.dialogRef.close()
        this.careersService.openCareerDetail(this.idCar)
        this.careersService.setEditForm(false);
      } else {
        this.dialogRef.close()
      }
    }

  async onCreateButtonClick(modify: boolean) {
    const selectedUnit = this.organizationalUnits.find((unit: { name: string; })=> unit.name === this.organizationalUnitName);
    if (!selectedUnit) {
    console.error('Unidad organizacional no encontrada para el nombre seleccionado');
    return;
  }
    const dataOU =
    {
      "name":this.name,
      "cod":this.name,
      "description":this.description,
      "career_level": this.level,
      "career_category": this.category,
      "orgUnitId": selectedUnit.idOgUnit.toString(),
    }
    if(modify){
      try {
        this.dataRes = await this.myApiService.patch(`careers/update/${this.data.idCar}`, dataOU);
        this.careersService.openCareerDetail(this.idCar)
        this.careersService.setEditForm(false)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    } else{
      try {
        this.dataRes = await this.myApiService.post('careers', dataOU);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    await this.careersService.loadCareers();

}


  editCareer(idCar:number): void {
    this.careersService.setEditForm(true)
    this.careersService.openModifyCareer(idCar)
    this.dialogRef.close()
  }

  title:string =""
  name:string =""
  description:string =""
  category:string =""
  level:string =""
  organizationalUnitName:string =""
  organizationalUnits: any = []
  isAcceptButtonEnabled: boolean =  false;
  inputActive:boolean = false;
  acceptButton:string=""
  cancelButton:string=""
  modify: boolean = false;
  idCar: number = 0;
  orgUnitId :  number = 0;

  ngOnInit() {
    // Evita que el Mat Dialog se cierre por el uso del teclado Esc y al clickear por fuera del modal
    this.dialogRef.disableClose = true;
    this.title = this.data.title || '';
    this.name = this.data.name || '';
    this.description = this.data.description || '';
    this.category = this.data.category || '';
    this.level = this.data.level || '';
    this.organizationalUnitName = this.data.organizationalUnitName || '';
    this.organizationalUnits = this.data.organizationalUnits || [];
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.inputActive = this.data.inputActive || false;
    this.isAcceptButtonEnabled = false;
    this.modify = this.data.modify || false;
    this.idCar = this.data.idCar || 0;
    this.orgUnitId  = this.data.orgUnitId  || 0;
  }

  onInputChange() {
    this.updateAcceptButtonState();
  }


  private updateAcceptButtonState() {
    this.isAcceptButtonEnabled = !!this.name && !!this.description  && !!this.category  && !!this.organizationalUnitName  && !!this.level;
  }
}
