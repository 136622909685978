import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/app.state';
import { logIn } from 'src/app/state/actions/auth.actions';

import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule, Routes } from '@angular/router';

@Component({
  selector: 'login-component',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    RouterModule,
  ],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  
  constructor( private store: Store<AppState> ) { }

  // We subscribe to hear the changes in the Store
  ngOnInit() {
    this.store.select('auth').subscribe( logInState => {
      console.log(logInState);
    })
  }

  hide = true;
  login() {
    // Implement login logic 
    const payload = {
      username: "Juan",
      password: "1"
    };
    const accion = logIn(payload);
    this.store.dispatch( accion );
  }
}
