<term-header></term-header>
<app-summary-term></app-summary-term>
<table-term></table-term>
<div style="display: flex; flex-direction: row; align-items: center;">
  <div>
    <button mat-button (click)="openCreateTerm()">Nueva</button>
  </div>
  <div>
    <button mat-button (click)="openModifyTerm()">Modificar</button>
  </div>

</div>
  
