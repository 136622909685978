<div *ngIf="currentPeriod" class="summary">
  <div class="summary-header-container" *ngIf="currentPeriod">
    <div><b>Periodo Actual:</b> {{ currentPeriod!.name }}</div>
    <div class="button-container">
      <div>
        {{ currentPeriod!.startDateTerm }} -
        {{ currentPeriod!.endDateTerm }}
      </div>
      <div class="left-content">
        <mat-chip
          class="mat-chip summary-button"
          [class.pointer-on-hover]="true"
        >
          <div class="chip-content">
            <mat-icon class="edit-icon">edit</mat-icon>
            <button class="chip-text">{{ "Editar" }}</button>
          </div>
        </mat-chip>
      </div>
    </div>
  </div>
  <div class="summary-container" *ngIf="currentPeriod">
    <div class="summary-body-container">
      <div
        class="summary-body-row"
        *ngFor="let timeTerm of currentPeriod!.timeTerm; let last = last"
      >
        <div>
          {{ timeTerm.typeName }}
        </div>
        <div class="summary-dates">
          {{ timeTerm.startDate }} -
          {{ timeTerm.endDate }}
        </div>
      </div>
    </div>
  </div>
</div>
<app-empty-state
  *ngIf="!currentPeriod"
  [errorCode]="errorCode"
></app-empty-state>
