import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login.component';
import { HomePageComponent } from './pages/home/home.component';
import { OrganizationalUnitsComponent } from './pages/organizational-units/organizational-units.component';
import { TermComponent } from './pages/term/term.component';
import { CareersComponent } from './pages/careers/careers.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'home', component: HomePageComponent, children: [] },
  { path: 'unidades-organizacionales', component: OrganizationalUnitsComponent },
  { path: 'periodos', component: TermComponent },
  { path: 'carreras', component: CareersComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: LoginPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
