import { createReducer, on } from "@ngrx/store";
import { logIn } from "../actions/auth.actions";

export interface AuthState {
    isLoggedIn: boolean,
}

export const initialState: AuthState = {
    isLoggedIn: false,
}

export const authReducer = createReducer (
    initialState,
    on(logIn, (state) => ({
        ...state,
        isLoggedIn: true,
    }))
)
