import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChildren, NgZone } from '@angular/core';
import { FormsModule, NgModel, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import * as moment from 'moment';

import { NotSavedModalComponent } from '../../components/not-saved-modal/not-saved-modal.component';
import { MyApiService } from 'src/services/connection';
import { PeriodoService } from 'src/services/term-service';

@Component({
  selector: 'app-term-modal',
  templateUrl: './term-modal.component.html',
  styleUrls: ['./term-modal.component.sass'],
  standalone: true,
  imports: [
    MatDialogModule, 
    MatButtonModule, 
    FormsModule,
    ReactiveFormsModule,
    CommonModule, 
    MatDatepickerModule, 
    MatInputModule, 
    MatFormFieldModule, 
    MatNativeDateModule,
  ],
})
export class TermModalComponent implements OnInit {

  notSavedDialogRef: MatDialogRef<NotSavedModalComponent> | undefined;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TermModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private zone: NgZone,

    private periodoService: PeriodoService, 
    private myApiService: MyApiService
  ) { }

  title: string =""
  name: string = ""
  description: string = ""
  acceptButton: string = ""
  cancelButton: string = ""
  isAcceptButtonEnabled: boolean = false;
  dataTerm: any
  modifyTerm: boolean = false;
  
  @ViewChildren('startDateInput', { read: NgModel }) startDateInput: NgModel[];
  @ViewChildren('endDateInput', { read: NgModel }) endDateInput: NgModel[];
 
  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        // Restablecer touched a true para los inputs de fecha de inicio
        if (this.startDateInput) {
          this.startDateInput.forEach(input => {
            input.control.markAsTouched();
          });
        }

        // Restablecer touched a true para los inputs de fecha de fin
        if (this.endDateInput) {
          this.endDateInput.forEach(input => {
            input.control.markAsTouched();
          });
        }
      });
    });
  }

  ngOnInit() {
    // Evita que el Mat Dialog se cierre 
    this.dialogRef.disableClose = true;

    this.title = this.data.title || '';
    this.name = this.data.name || '';
    this.description = this.data.description || '';
    this.acceptButton = this.data.acceptButton || '';
    this.cancelButton = this.data.cancelButton || '';
    this.isAcceptButtonEnabled = false;
    this.modifyTerm = this.data.modifyTerm || false;
    
    this.dataTerm = this.data.dataTerm || 
    {
      name: "",
      description: "string",
      active: "true",
      timeTerm: [
        {
          startDate: "",
          endDate: "",
          orderTime: 2,
          id: 2,
          minStartDate: "",
          minEndDate: "",
          maxStartDate: "",
          typeTimeTerm: "Control y Aprobación de la Solicitud de Compra"
        },
        {
          startDate: "",
          endDate: "",
          orderTime: 3,
          id: 3,
          minStartDate: "",
          minEndDate: "",
          maxStartDate: "",
          typeTimeTerm: "Convocatoria de Cotización"
        },
        {
          startDate: "",
          endDate: "",
          orderTime: 1,
          id: 1,
          minStartDate: "",
          minEndDate: "",
          maxStartDate: "",
          typeTimeTerm: "Convocatoria de Carga"
        },
        {
          startDate: "",
          endDate: "",
          orderTime: 4,
          id: 4,
          minStartDate: "",
          minEndDate: "",
          maxStartDate: "",
          typeTimeTerm: "Adjudicación y Orden de Compra"
        }
      ]
    }

    // DataTerm Ordered by TimeTerm
    this.dataTerm.timeTerm.sort((a, b) => a.orderTime - b.orderTime);    
  }


  getMinDate(term: any, inputType: string) {
    // console.log(term);
    // console.log(inputType);

    for (let index = 1; index < this.dataTerm.timeTerm.length + 1; index++) {
      if (term.id === index){
        if (inputType === 'endDate' && index !== 4) {
          const minTerm = this.dataTerm.timeTerm.find((termLast) => (termLast.id - 1) === term.id)
          // console.log("Aqui ---------------");        
          // console.log(minTerm);
          // console.log("Aqui ---------------");
          minTerm.minStartDate = term.endDate.clone().add(1, 'days');
          minTerm.maxStartDate = term.endDate.clone().add(1, 'days');
        } else {
          const minTerm = this.dataTerm.timeTerm.find((termLast) => (termLast.id) === term.id)
          // console.log("Aqui ---------------");        
          // console.log(minTerm);
          // console.log("Aqui ---------------");
          minTerm.minEndDate = term.startDate
        }
      }
    }
  }

  // Valida que los inputs de los Datepickers esten correctamente cargados
  validateDate(termId: any) {
    const datepickers = document.querySelectorAll('input');
    const datepickersArray = Array.from(datepickers).filter((input: any) => input.id !== "title" && input.id !== "search-input");
    // console.log(datepickers);  
    // console.log(datepickersArray);

    var isInvalidExist = false
    var isUnfilledExist = false
    this.isAcceptButtonEnabled = false
    
    // Validate element Value
    for (const datepicker of datepickersArray) {
      const isInvalid = datepicker.ariaInvalid;

      // Remove warnTerm Style at Datepicker
      if (termId !== 0) {
        const element = document.querySelector(`mat-form-field#${datepicker.id}`);
        element.classList.remove('warnTerm');
      }

      // Check if the Datepicker has valid dates
      if (isInvalid === "true") {       
        // Add warnTerm Style at Datepicker
        const element = document.querySelector(`mat-form-field#${datepicker.id}`);
        element.classList.add('warnTerm');
        
        isInvalidExist = true
      } 
    }

    // Validate element Filled
    if (!isInvalidExist) {
      for (const datepicker of datepickersArray) {
        const isFilled = datepicker.value

        // Check if the Datepicker is filled
        if (this.name === "") {
          isUnfilledExist = true
        } else if (isFilled === "") {
          isUnfilledExist = true
        }
      }
    }

    if (!isInvalidExist && !isUnfilledExist) {
      this.isAcceptButtonEnabled = true
    }
  }

  // Validate Title input
  onInputChange() {
    this.dataTerm.name = this.name
    this.validateDate(0)
  }

  // Validate Datepicker
  onValueChange(term: any, inputType: string) {
    // console.log(this.dataTerm);
    // console.log(term);

    this.getMinDate(term, inputType);
    
    // The function runs after the getMinDate function
    setTimeout(() => {
      this.validateDate(term.id)
    }, 0);
  }

  dataTermRes: any

  async onAcceptButtonClick() {
    var dataTermSend = {
      "name": this.dataTerm.name,
      "description": "string",
      "active": "true",
      "timeTerm": this.dataTerm.timeTerm.map( (element, index) => {
        return {
          startDate: moment(element.startDate).format("DD/MM/YYYY"),
          endDate: moment(element.endDate).format("DD/MM/YYYY"),
          orderTime: index + 1,
          typeTimeTerm: index + 1
        }
      })
    };

    // console.log(this.dataTerm);
    // console.log(dataTermSend);

    // Seteando fechas del periodo
    var startDateTerm
    var endDateTerm

    dataTermSend.timeTerm.forEach(element => {
      if (element.orderTime === 1) {
        startDateTerm = element.startDate
      } else if (element.orderTime === 4) {
        endDateTerm = element.endDate
      }
    });

    // Term Validation
    const periodoValido = await this.periodoService.agregarPeriodo({      
      startDateTerm: moment(startDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD"),
      endDateTerm: moment(endDateTerm, "DD/MM/YYYY").format("YYYY-MM-DD")
    });

    if (periodoValido) {
      if (!this.modifyTerm) {
        // Create Term
        try {
          this.dataTermRes = await this.myApiService.post('term', dataTermSend);
          this.dialogRef.close()
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      } else {
        // Modify Term
        try {
          this.dataTermRes = await this.myApiService.patch(`term/${this.dataTerm.id}`, dataTermSend);
          this.dialogRef.close()       
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }   
    } else {
      console.log("No se puede validar el periodo");
    }
  }

  onCancelButtonClick(): void {    
    if (this.isAcceptButtonEnabled) {
      this.notSavedDialogRef = this.dialog.open(NotSavedModalComponent, {
        data: {
          title:"¡Espera un momento!",
          subtitle:"No has guardado tus datos. Si sales ahora, perderás el progreso de los mismos.",
          subtitleQuestion:"¿Deseas salir sin guardar?",
          acceptButton:"Si, descartar",
          cancelButton:"Cancelar",
          icon: true,
        }
      });
      this.notSavedDialogRef.afterClosed().subscribe(result => {
        // console.log(`NotSavedModal result: ${result}`);
        if (result === 'accept') {
          this.dialog.closeAll()
        }
      });
    } else {
      this.dialogRef.close()
    }
  }
}
