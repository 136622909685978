import { ActionReducerMap } from "@ngrx/store";
import { AuthState, authReducer } from "./reducers/auth.reducer"
import { OrganizationalUnitsState, organizationalUnitsReducer } from "./reducers/organizationalUnit.reducer";
import { CareersState, careersReducer } from "./reducers/careers.reducer";
export interface AppState {
    test: AuthState,
    auth: AuthState,
    organizationalUnit: OrganizationalUnitsState,
    careers: CareersState
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
    test: authReducer,
    auth: authReducer,
    organizationalUnit: organizationalUnitsReducer,
    careers: careersReducer
}
