import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { OrganizationalUnitModal } from 'src/app/pages/organizational-units/organizational-unit-modal/organizational-unit-modal.component';
import { OrganizationalUnits } from '../organizational-unit.service';


@Component({
  selector: 'app-header-table',
  standalone: true,
  imports: [CommonModule, MatChipsModule, OrganizationalUnitModal],
  templateUrl: './organizational-unit-header.component.html',
  styleUrls: ['./organizational-unit-header.component.sass'],
})

export class HeaderTableComponent {
  title: string = 'Unidades Organizacionales';
  chipText: string = 'Nueva Unidad Organizacional';
  searchText: string = '';
  dialog: any;

  constructor(
    private organizationalUnitsService: OrganizationalUnits
    ) {}

  async openCreateOrganizationalUnit() {
    this.organizationalUnitsService.openCreateOrganizationalUnit();
  }
}
