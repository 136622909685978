<mat-card class="quick-card">
  <mat-card-header>
    <h2>
      {{ title }}
    </h2>
    <h4>
      {{ subtitle }}
    </h4>
  </mat-card-header>
  <mat-card-content [ngClass]="{ singleRow: singleRow }">
    <div *ngFor="let action of actions">
      <app-icon-button
        [icon]="action.icon"
        [coloredIcon]="action.coloredIcon"
        [title]="action.title"
        class="app-icon-button"
        routerLink="/{{ action.slug }}"
        routerLinkActive="btn-page-active"
      ></app-icon-button>
    </div>
  </mat-card-content>
</mat-card>
